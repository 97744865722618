import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MyComponent from "./components/MyComponent/MyComponent.js";
import Menu from "./components/Menu/Menu.js";
import Footer from "./components/Footer/Footer.js";
import "./components/Menu/Menu.css";
import './index.css'
import Home from "./pages/Home/Home.js";
import Cases from "./pages/Cases/Cases.js";
import UnderConstruction from "./pages/UnderConstruction/UnderConstruction.js";
import Animation from "./pages/Animation/Animation.js";
import Video from "./pages/Videos/videos.js";
import Form from "./pages/Form/Form.js";
import VR from "./pages/VR/VR.js";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { preload } from 'react-dom';


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {

  return (
    <BrowserRouter>
    

    <Menu/>
    <div className='Menu-background desktop'></div>
  <ScrollToTop />

      <Routes>
        <Route path="/" element={<Home />}>
        </Route>
        <Route path="/Cases" element={<Cases />}>
        </Route>
        <Route path="/VR" element={<VR />}>
        </Route>
        <Route path="/UnderConstruction" element={<UnderConstruction />}>
        </Route>
        <Route path="/Animation" element={<Video />}>
        </Route>
        <Route path="/Videos" element={<Video />}>
        </Route>
        <Route path="/Form" element={<Form />}>
        </Route>
      </Routes>
    <Footer/>

    </BrowserRouter>
  );
}

export default App;
