import React from 'react';

import './videos.css'
import '../../index.css'
import arrow from './images/arrow.png';


import Main from './images/Main.png';
import Dado from './images/DadoBeer.png';
import DadoM from './images/DadoBeerMobile.png';
import Beauty from './images/BM_Beauty.png';
import BeautyM1 from './images/BM1.png';
import BeautyM2 from './images/BM2.png';


import video1 from './videos/video1.mp4'
import video2 from './videos/video2.mp4'
import video3 from './videos/video3.mp4'
import video4 from './videos/video4.mp4'

import mauricio1 from './images/Mauricio/1.png'
import mauricio2 from './images/Mauricio/2.png'
import mauricio3 from './images/Mauricio/3.png'
import mauricio4 from './images/Mauricio/4.png'

import auto1 from './images/AutoPass/1.png'
import auto2 from './images/AutoPass/2.png'
import auto3 from './images/AutoPass/3.png'
import auto4 from './images/AutoPass/4.png'

import ScrollContainer from 'react-indiana-drag-scroll'



function Content(props){
  var title=props.title;
  var img=props.img;
  var content=props.content
 

  return(
    <div className='flex col align content' >
      
      <div className='flex row'>
          <div className='content-text flex col align'>
          <div className='gradient-text'><h2>{title}</h2></div>
            {content}
            <div className='bot button gradient-text'>Saiba Mais</div>

          </div>
          <div className='content-text flex col'>
          {img}
          </div>
         
      </div>
    </div>
  );

}
var currentVideo=0;
var maxVideos=3;
function moveMobileRight(){
  if(currentVideo>=maxVideos)return;
  currentVideo++;
  
  moveMobile();
}
function moveMobileLeft(){
  if(currentVideo<=0)return;
  currentVideo--;
  moveMobile();
}

function moveMobile(){
  if(currentVideo==0){
    document.getElementById('arrow1').style.opacity=0.25;
  }
  else{
    document.getElementById('arrow1').style.opacity=1;
  }

  if(currentVideo==maxVideos){
    document.getElementById('arrow2').style.opacity=0.25;
  }
  else{
    document.getElementById('arrow2').style.opacity=1;
  }
  document.getElementById('mobileVideoScroll').style.marginLeft= (currentVideo*-200)+'VW';

}


class blurScroll {
  constructor(id,start,end,blur, revealatEnd) {
    this.id=id;
    this.start=start;
    this.end=end;
    this.total = end-start;
    this.blur=blur;
    this.prevV=99;
    this.revealatEnd=revealatEnd;
    this.hasRevealed=false;
  }
  animate(scrollY) {
    if(document.getElementById(this.id)==null){
      return;
    }
    if(scrollY>this.start){
      var v= this.end-scrollY;
      v=v/this.total;
      
      if(v>this.prevV){
        v=this.prevV;
      }
      if(v<=0&&this.hasRevealed==false){
        if(this.revealatEnd!='none'&&this.revealatEnd!=undefined){
          document.getElementById(this.revealatEnd).style.display='flex'
        }
      }
      if(v<0){
        v=0;
      }
      this.prevV=v;
      var b = (v)*this.blur;
    }
    if(this.prevV==0){
      for(var i=0;i<animations.length;i++){
        if(animations[i].id==this.id){
          animations.splice(i,1);
        }
      }
      
    }
    document.getElementById(this.id).style.backdropFilter = 'blur('+b+'px)';
  }
}

var animations=[];

  


var nVideos=5;
var inertia=0;
var loop=null;
var loop_count=0;
var prevX=0;
var UseSnap=true;
var snapSize=1320;
var numberOfBlocks=3;
loop = setInterval(inertialoop, 10);
var canScroll=false;
var totalLoops=50;

function inertialoop(){
  for(var i =0;i<animations.length;i++){
    
    animations[i].animate(document.documentElement.scrollTop);
  }
  if(loop_count>0){
    var element=document.getElementById('fuckyou').parentElement;
    var container=document.getElementById('fuckyou');
    
    var target =element.scrollLeft + (inertia);
    
    element.scrollTo(target,0)
    
    //console.log("inertia "+inertia +" target "+element.scrollLeft);
    //console.log("loop "+loop_count);
    //inertia=inertia*0.5;
    loop_count--;
    if(loop_count==0){
      inertia=0;
      prevX=element.scrollLeft;
    }
  }
  
  
    
}


function Video (props){
  var video_height=500;
  var video_width=300;
  animations=[]
  animations.push(new blurScroll('dadoBlur',500,window.innerHeight+200,15,'dadoTitle'));
  var mainHeight= (1080/1920) * document.body.scrollWidth;
  var mobileHeight = document.body.scrollHeight;
  const handleMouseDown = () => {
    inertia=0;
    

    loop_count=0;
};
  
  
  return(
    <>
  <div className='main-Cases desktop' style={{ 
    backgroundImage: `url(${Main})`,
    minHeight:mainHeight
  }}>


      <div className='flexCol ' >
        <div className='blur'></div>
        <div className='zIndex title-main'>Animações e Produção de Imagens 3D</div>
        <div className='zIndex text-main marginTop10'>A Napalm Studio cria animações e imagens em 3D para diversos segmentos,  trabalhamos no estilo cartoon. </div>
        <ScrollContainer style={{margin:'auto'}}  className="scroll-container centralize "
          onScroll={(event) => {
            if(loop_count==0){
              inertia=document.getElementById('fuckyou').parentElement.scrollLeft -prevX;
              prevX=document.getElementById('fuckyou').parentElement.scrollLeft;
            }

          }}
          onClick={(event) => {
            inertia=0;
            loop_count=0;
            console.log('onClick', event);
          }}

          onEndScroll={(event) => {

              for(var i=0;i<nVideos;i++){
                var element = document.getElementById('car_vid_'+i);
                if(element!=null){
                  
                  element.pause()
                }
              }
              if(UseSnap && inertia!=0){
                var direction= inertia /( Math.sqrt (inertia*inertia));
      
                var dif = document.getElementById('fuckyou').scrollLeft + direction*snapSize;
                var parent_child_dif = document.getElementById('fuckyou').parentNode.scrollLeft;
                
                
                var target=(Math.round((dif/snapSize))*snapSize) -parent_child_dif;
                inertia=target/totalLoops;
                console.log("parent_child_dif "+parent_child_dif)
                console.log("inertia "+inertia +" target "+document.getElementById('fuckyou').scrollLeft);
                //inertia= (dif - ((dif+document.getElementById('fuckyou').parentElement.scrollLeft)%snapSize))/totalLoops;
              
              }
                loop_count=totalLoops;
            
            
            console.log('onEndScroll', event);
          }}
        >
          { <div id='fuckyou' onMouseDown={handleMouseDown} className='test flex-mine'>
              <div className='bigBox'>
                <video id='car_vid_0' className='fill' height={video_height}  width={video_width} controls >
                  <source src={video1} type="video/mp4"/>
                </video>
                <video id='car_vid_1' className='fill' height={video_height} width={video_width} controls >
                  <source src={video2} type="video/mp4"/>
                </video>
                <video id='car_vid_2' className='fill' height={video_height} width={video_width} controls >
                  <source src={video3} type="video/mp4"/>
                </video>
                <video id='car_vid_2' className='fill' height={video_height} width={video_width} controls >
                  <source src={video4} type="video/mp4"/>
                </video>
              </div>
              
              
              
            </div>
          }
        </ScrollContainer>

      </div>
  </div>
  <div className='main-Cases desktop' style={{ 
    backgroundImage: `url(${Dado})`,
    minHeight:mainHeight
  }}>
    <div id='dadoBlur' className='blur'></div>
    <div id='dadoTitle' className='dado-title'>

      DADO BIER
    </div>
  </div>
  

  <div className='main-Cases desktop' style={{ 
    backgroundImage: `url(${Beauty})`,
    minHeight:mainHeight
  }}>
    <div className='flexCol beauty-title'>
      <h1>BM BEAUTY</h1>
      <h2>A Napalm Studio tem satisfação em produzir as versões digitais dos produtos Bm Beauty</h2>
    </div>
  </div>
  <div className='collageTitle desktop'>Maurício e os Imaginários</div>
  <div className='flexRow desktop'>
    <img className='collageImg' src={mauricio1}/>
    <img className='collageImg' src={mauricio2}/>
  </div>
  <div className='flexRow desktop'>
    <img className='collageImg' src={mauricio3}/>
    <img className='collageImg' src={mauricio4}/>
  </div>

  <div className='collageTitle2 desktop'>Autopass TOP</div>
  <div className='flexRow desktop' style={{flexWrap:'wrap',
    width:'100%'
  }}>
    <img className='collageImg' src={auto1}/>
    <img className='collageImg' src={auto2}/>
  </div>
  <div className='flexRow desktop'>
    <img className='collageImg' src={auto3}/>
    <img className='collageImg' src={auto4}/>
  </div>

  {/* !!!!! MOBILE !!!!!*/}
  <div className='mobile-main mobile' style={{ 
    backgroundImage: `url(${Main})`,
    
  }}>
    <div className='blur'></div>
    
    <div className='mobileArrow'>
      <img id='arrow1' src={arrow} className='flipHorizontal' style={{opacity:0.25}} onClick={moveMobileLeft}></img>
      <img id='arrow2' src={arrow} className='leftAuto' onClick={moveMobileRight} ></img>
    </div>
    <div id='mobileVideoScroll' className='flexRow'>
      <video  controls >
        <source src={video1} type="video/mp4"/>
      </video>
      <video  controls >
        <source src={video2} type="video/mp4"/>
      </video>
      <video  controls >
        <source src={video3} type="video/mp4"/>
      </video>
      <video  controls >
        <source src={video4} type="video/mp4"/>
      </video>

    </div>
    <div className='zIndex title-main-mobile'>Animações e Produção de Imagens 3D</div>
  </div>
    
  <div className='mobile-main mobile' style={{ 
    backgroundImage: `url(${DadoM})`,
    height:'56.25vw'
  }}>
    <div className='dado-title' style={{display:'flex'}}>
      DADO BIER
    </div>
  </div>
  <div className='mobile-main mobile' style={{ 
    backgroundImage: `url(${BeautyM1})`,
    height:'108vw'
    
  }}>
    <div  style={{display:'flex'}}>
    <div className='flexCol beauty-title-mobile'>
      <h1>BM BEAUTY</h1>
      <h2>A Napalm Studio tem satisfação em produzir as versões digitais dos produtos Bm Beauty</h2>
    </div>
    </div>
  </div>
  <div className='mobile-main mobile' style={{ 
    backgroundImage: `url(${BeautyM2})`,
    height:'108vw'
  }}>
    <div className='dado-title' style={{display:'flex'}}>
      
    </div>
  </div>
  <div className='collageTitle mobile'>Maurício e os Imaginários</div>
  <div className='flexCol mobile'>
    <img style={{width:'100%'}} src={mauricio1}/>
    <img style={{width:'100%'}} src={mauricio2}/>
    <img style={{width:'100%'}} src={mauricio3}/>
    <img style={{width:'100%'}} src={mauricio4}/>
  </div>

  <div className='collageTitle2 mobile'>Autopass TOP</div>
  <div className='flexRow mobile' style={{flexWrap:'wrap',
    width:'100%'
  }}>
    <img style={{width:'100%'}} src={auto1}/>
    <img style={{width:'100%'}} src={auto2}/>
    <img style={{width:'100%'}} src={auto3}/>
    <img style={{width:'100%'}} src={auto4}/>
  </div>

  </>
  );
}


Video.propTypes = {};

Video.defaultProps = {};

export default Video;
