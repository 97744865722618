import React from 'react';
import PropTypes from 'prop-types';
import './Home.css'
import '../../index.css'
import PageButton from '../../components/PageButton/PageButton';

import napalmlogo from './images/NapalmLogo_big.png';
import headset from './videos/Headset.mp4';
import headset2 from './images/headset2.png';

import corporativo from './images/corporativo.png';
import top from './images/Tap.png';
import jogos from './images/Jogos.png';
import GltfScene from '../../components/gltf_scene/gltf_scene';
import ReactPlayer from 'react-player'

//PAGEBUTTONS
import heroi from '../../content/images/heroi.png';
import smartphone from '../../content/images/celular.png';
import b_headset from '../../content/images/head-set.png';
import tela from '../../content/images/Tela_.png';
import pasta from '../../content/images/Pasta.png';

//GIFS
import celularGif from '../../content/gifs/celular.gif';
import heroiGif from '../../content/gifs/heroi.gif';
import b_headsetGif from '../../content/gifs/head-set.gif';
import telaGif from '../../content/gifs/Tela_.gif';
import pastaGif from '../../content/gifs/Pasta.gif';

function Content(props){
  var title=props.title;
  var img=props.img;
  var content=props.content
  
  return(
    <div className='flex col align content' >
      <div className='gradient-text'><h2>{title}</h2></div>
      <div className='flex row'>
        <div className='content-text flex col align'>
          <div className='mobile'>{img}</div>
          {content}
          <div className='bot button gradient-text'>Saiba Mais</div>

        </div>
        <div className='content-text flex col align desktop'>
          {img}
        </div>
         
      </div>
    </div>
  );

}


const Home = () => (
  <div className='main-home'>
    <div className="video-container">
        <div className="video-overlay">
            <div className="text-overlay">JOGOS,</div>
            <div className="text-overlay">REALIDADE</div>
            <div className="text-overlay">VIRTUAL,</div>
            <div className="text-overlay">ANIMAÇÕES</div>
            <div className="text-overlay">PARA</div>
            <div className="text-overlay">EMPRESAS</div>
        </div>
        <video  id="bg-video" className='desktop'  src="./videos/Capa_website_V02.mp4" autoPlay muted loop type="video/mp4"/>
        <video  id="bg-video" className='mobile'  src="./videos/Capa_website_V02.mp4" autoPlay muted loop type="video/mp4"/>
    </div>

    <div className='flex row align button-container wrap top-50p bot-50p desktop'>
        <PageButton Text='Aprendizagem Corporativa' img={smartphone} gif={celularGif} Link='/UnderConstruction'/>
        <PageButton Text='Realidade Virtual ' img={b_headset} gif={b_headsetGif} Link='/VR'/>
        <PageButton Text='Animações Imagens 3D' img={tela} gif={telaGif} Link='/Animation'/>
        <PageButton Text='Jogos e Aplicativos' img={heroi} gif={heroiGif} Link='/UnderConstruction'/>
        <PageButton Text='Cases' img={pasta} gif={pastaGif} Link='/Cases'/>
    </div>
    <div className='flex col align'>
        <img  className='home-logo' src={napalmlogo}/>
        <div className='home-max-size'>A Napalm Studio é uma empresa com mais de 10 anos de experiência no desenvolvimento de jogos e animações. Atualmente trabalhamos com foco no universo corporativo, atendendo diversas empresas desenvolvendo jogos para treinamento corporativo, Realidade Virtual, aplicativos, imagens e animações.
              Trabalhamos para que sua empresa desenvolva ações personalizadas que conciliem os objetivos estratégicos com a mistura de entretenimento, tecnologia, motivação e cultura de dados.
        </div>
        <video  className='fill top-50p maxWidth70'  src="./videos/Reel_Website_V02.mp4" controls type="video/mp4"/>

    </div>
    
    <Content img={<img className='fill rotate' src={corporativo}/>} content={
      <div>A Napalm Studio tem como um de seus principais o produtos o desenvolvimento de Jogos para Treinamento Corporativo. Misturando entretenimento, tecnologia, aprendizagem e cultura de dados criamos uma solução gamificada onde o colaborador participa de um game mobile para melhorar o seu processo de aprendizagem.<br/><br/>
      Com mais de 10 anos de experiência no desenvolvimento de jogos digitais conseguimos criar jogos mobile tecnicamente bem desenvolvidos, o que gera uma maior engajamento de seus usuários. Contamos com um sistema que de gestão de dados para controle e feedback para a empresa, permitindo o gerenciamento do APP assim como uma robusta análise das atividades pedagógicas da equipe.
      </div>
    
    }
    
    title='Gamificação e jogos para treinamento Corporativo'/>
    <Content img={
      <img className='fill rotate-reverse' src={headset2}/>
   
    } content={'Napalm Studio tem como um de seus principais o produtos o desenvolvimento de Jogos para Treinamento Corporativo. Misturando entretenimento, tecnologia, aprendizagem e cultura de dados criamos uma solução gamificada onde o colaborador participa de um game mobile para melhorar o seu processo de aprendizagem.Com mais de 10 anos de experiência no desenvolvimento de jogos digitais conseguimos criar jogos mobile tecnicamente bem desenvolvidos, o que gera uma maior engajamento de seus usuários. Contamos com um sistema que de gestão de dados para controle e feedback para a empresa, permitindo o gerenciamento do APP assim como uma robusta análise das atividades pedagógicas da equipe'} title='Realidade Virtual e Realidade Aumentada'/>
    <Content img={<img className='fill rotate' src={top}/>} content={'Desenvolvemos animações, vídeos e imagens para empresas, ajudando a construir um ecosistema mais lúdico e amigável. Podemos resolver aquela demanda pontual e urgente, mas acreditamos que construindo uma estratégia de produção, podemos baixar custos e tempo de produção sem abrir mão da qualidade. Podemos desenvolver bancos de imagens, mockups e animções para os mais diversos usos, inserção em apresentações, Giffs, figurinhas, enfim... o que a sua empresa precisar.'} title='Animações e Imagens'/>
    <Content img={<img className='fill rotate-reverse' src={jogos}/>} content={'Jogos digitais sempre foram a nossa praia! Temos muita experiência nisso, desenvolvemos jogos por demanda para diversos empresas e diferentes países. Nossa caminhada começou criado modelos para Plaustation 2, passando por XBOX , jogos para PC e smartphones.Ao longo da jornada aprendemos a criar jogos mais leves, mais eficientes e com sistemas mais complexos. Todo esse conhecimento acabou sendo transferido para outras áreas e em certo momento começamos a desenvolver sistemas, websites e aplicativos também.'} title=' Jogos Digitais e Aplicativos'/>
  </div>
);

Home.propTypes = {};

Home.defaultProps = {};

export default Home;
