import React from 'react';
import PropTypes from 'prop-types';
import './Cases.css'
import '../../index.css'
import PageButton from '../../components/PageButton/PageButton';

import napalmlogo from './images/NapalmLogo_big.png';
import headset2 from './images/headset2.png';

import lebes from './images/lebes.png';
import stihl from './images/Stihl.png';
import g8 from './images/g8.png';
import Coopercarga from './images/Coopercarga.png';
import expresso from './images/expresso.png';
import top from './images/Tap.png';
import aurora from './images/aurora.png';
import SBD from './images/SBD.png';
import jogos from './images/Jogos.png';
import imagina from './images/imagina.png';

import GltfScene from '../../components/gltf_scene/gltf_scene';
import ReactPlayer from 'react-player'
//PAGEBUTTONS
import heroi from '../../content/images/heroi.png';
import smartphone from '../../content/images/celular.png';
import b_headset from '../../content/images/head-set.png';
import tela from '../../content/images/Tela_.png';
import pasta from '../../content/images/Pasta.png';

//GIFS
import celularGif from '../../content/gifs/celular.gif';
import heroiGif from '../../content/gifs/heroi.gif';
import b_headsetGif from '../../content/gifs/head-set.gif';
import telaGif from '../../content/gifs/Tela_.gif';
import pastaGif from '../../content/gifs/Pasta.gif';


function Content(props){
  var title=props.title;
  var img=props.img;
  var content=props.content
  
  return(
    <div className='flex col align content' >
      
      <div className='flex row'>
          <div className='content-text flex col align'>
            <div className='gradient-text'><h2>{title}</h2></div>
            <div className='content-text flex col mobile'>
              {img}
            </div>
            {content}
            <div className='bot button gradient-text'>Saiba Mais</div>

          </div>
          <div className='content-text flex col desktop'>
          {img}
          </div>
         
      </div>
    </div>
  );

}


const Cases = () => (
  <div className='main-Cases'>
    <div className="video-container">
        <div className="video-overlay">
            <div className="text-overlay">CONHEÇA</div>
            <div className="text-overlay">NOSSOS</div>
            <div className="text-overlay">PROJETOS</div>
            
        </div>
        <video  id="bg-video" src="./videos/Cases.mp4" autoPlay muted loop type="video/mp4"/>
    </div>

    <div className='flex row align button-container wrap top-50p bot-50p desktop'>
      <PageButton Text='Aprendizagem Corporativa' img={smartphone} gif={celularGif} Link='/UnderConstruction'/>
      <PageButton Text='Realidade Virtual ' img={b_headset} gif={b_headsetGif} Link='/VR'/>
      <PageButton Text='Animações Imagens 3D' img={tela} gif={telaGif} Link='/Animation'/>
      <PageButton Text='Jogos e Aplicativos' img={heroi} gif={heroiGif} Link='/UnderConstruction'/>
      <PageButton Text='Cases' img={pasta} gif={pastaGif} Link='/Cases'/>
    </div>
    
    <Content img={<img className='fill rotate-reverse' src={lebes}/>} content={'Em 2017 em parceria com a 818 consulturoia aceitamos o desafio de criar um jogo para treinar a base de colaboradores das Lojas Lebes, um game que seria capaz de gerar um feedback sobre o aprendizado para a empresa sobre os mais diversos temas. O projeto foi aplicado para mais de 2.000 colaboradores, gerando mais de 700.000 respostas.Em 2018 o trabalho sério foi recompensado com a premiação do “Top ser Humano” da ABRH-RS.'} title='Planeta Lebes'/>
    <Content img={<img className='fill rotate' src={stihl}/>} content={'A Stihl é uma referência quando se trata de ferramentas motorizadas, sendo sinônimo de qualidade no setor. Em 2020 começamos a auxiliar a empresa no processo de virtualização de treinamentos específicos e complexos.Nosso foco é ajudar a criar treinamentos para sistemas complexos através da realidade virtual, permitindo aos colaboradores conhecer os sistemas de forma imersiva.'} title='Stihl VR'/>
    <Content img={<img className='fill rotate-reverse' src={jogos}/>} content={'A Napalmstudio também trabalha com a produção de imagens e mockups digitais. A partir de 2021 ficamos muito satisfeitos quando a cervejaria Dado Bier solicitou a produção de imagens de mockups digitais de suas cervejas.Assim como uma boa cerveja, depois da primeira latinha fica difíl parar e atualmente já produzimos dezenas de imagens para a empresa.'} title='Dado Bier'/>
    <Content img={<img className='fill rotate' src={g8}/>} content={'O G8 é um grupo de empreendedores do comércio e distribuição de materiais de construção, formado em 2010 com o objetivo de impulsionar o desenvolvimento e crescimento do varejo independente. Em 2019 iniciamos o “G8 Play “ que é motivo de muito orgulho, pois o jogo para treinamento corporativo já está em seu 3° ano de atividade e já passamos de mais de 1.800.000 respostas.'} title='G8 Play'/>
    <Content img={<img className='fill rotate-reverse' src={Coopercarga}/>} content={'A coopercarga é uma das maiores cooperativas de logística do Brasil, e em 2019 inicamos uma parceria para colaborar com o setor de Recursos Humanos para ajudar a criar novas ferramentas para o treinamento corporativo. Neste momento surgiram dois projetos, o Coopergame, prêmiado em 2020 pela ABRH SC e a Universidade Corporativa, um sistema LMS web ajustado as demandas específicas da empresa. '} title='Coopercarga'/>
    <Content img={<img className='fill rotate' src={expresso}/>} content={'A Expresso São Miguel, empresa do setor de logística, buscava uma forma de engajar sua equipe de forma lúdica mas efetiva. Em mais uma parceria com a 818 Consultoria a solução foi a criação de um jogo físico de tabuleiro. Depois de tantos anos produzindo jogos digitais, nossa equipe se divertiu pensando em cada detalhe, cada peça foi produzida em impressão 3D, tudo customizado para garantir uma experiência única para seus jogadores.'} title='Expresso São Miguel'/>
    <Content img={<img className='fill rotate-reverse' src={top}/>} content={'A Autopass é um empresa de bilhetagem, que atua em São Paulo. Atualemnte ajudamos a Autopass com a produção de vídeos institucionais e animações que auxiliam na comunicação da empresa com os usuários.Projeto que se deu início a partir da parceria de Rodrigo Valente e Edu Garretano.'} title='Autopass'/>
    <Content img={<img className='fill rotate' src={aurora}/>} content={'Aurora Aventuras é um game para crianças produzido para a Vinícula Aurora. Um universo lúdico com personagens divertidos que estão associados a promoção de sucos de uva para crianças.'} title='Vinícula Aurora'/>
    <Content img={<img className='fill rotate-reverse' src={SBD}/>} content={'Nossos jogos para treinamento corporativo chegaram a Stanley Black and Decker!Treinamento associado a campanha de vendas, esse projeto é construído em parceria com  a Value Builders, que trabalha com campanhas de vendas.'} title='Venda Mais SBD'/>
    <Content img={<img className='fill rotate' src={imagina}/>} content={'Parceria antiga com o grande Maurício Rech, a turminha do Maurício e os Imaginários é um projeto transmídia, que envolve shows, animações, livros e jogos. Essa turminha veio para praticar o bem e levar para a criançada uma atitude positiva, valorizando a reflexão, a ética e a solidariedade.'} title='Maurício e os Imaginários'/>
  </div>
);

Cases.propTypes = {};

Cases.defaultProps = {};

export default Cases;
