import React from 'react';
import PropTypes from 'prop-types';
import './Menu.css'
import burger from './images/MenuBurger.png'
import napalm from './images/napalm_logo.png'
import napalmM from './images/LogoTopbar.png'
import phone from './images/smartphone.png'
import cube from './images/cube.png'

import playCircle from './images/play-circle.png'
import gamepad from './images/gamepad.png'
import file from './images/File.png'
import '../../index.css'
import {  Link } from "react-router-dom";
const Mask = ({ mask }) => (
  
  <div className='menu-button-img' style={{
    
    WebkitMaskImage: mask,
    maskImage: mask,
    
    
  }}>

  </div>
)
function clickTopbar(){

  var element = document.getElementById('TopbarMenu');
  console.log(element.style.display)
  if(element.style.display=='none'){
    element.style.display='flex'
    return;
  }
  else{
    element.style.display='none'
  }
  
}

const Menu = (props) => (
  <>
  <div className='Topbar mobile' >
    <div  style={{marginLeft:'15px', zIndex:'9999'}} onClick={clickTopbar}>
      <img src={burger} ></img>
    </div>
    <img src={napalmM} style={{marginLeft:'auto'}}></img>

  </div>
  <div id='TopbarMenu' className='MenuMobile mobile' style={{display:'none'}}>
    
    <div className='menu-button-container'
    style={{marginTop:'120px',width:'100%'}}>

      <Link onClick={clickTopbar} to="/UnderConstruction" className='no-decoration MenuButtonMobile'>
        <div className='menu-button MenuButtonMobile' >
          <div className='menu-button-text'>Aprendizagem</div>
          <Mask mask={'url('+phone+')'}/>
        </div>
      </Link>
      
      <Link onClick={clickTopbar} to="/VR" className='no-decoration MenuButtonMobile'>
        <div className='menu-button MenuButtonMobile'>
          <div className='menu-button-text'>Realidade Virtual</div>
          <Mask mask={'url('+cube+')'}/>
        </div>
      </Link>

      <Link onClick={clickTopbar} to="/Animation" className='no-decoration MenuButtonMobile'>
        <div className='menu-button MenuButtonMobile'>
          <div className='menu-button-text'>Animações 3D</div>
          <Mask mask={'url('+playCircle+')'}/>
        </div>
      </Link>

      <Link onClick={clickTopbar} to="/UnderConstruction" className='no-decoration MenuButtonMobile'>
        <div className='menu-button MenuButtonMobile'>
          <div className='menu-button-text'>Jogos e Aplicativos</div>
          <Mask mask={'url('+gamepad+')'}/>
        </div>
      </Link>

      <Link onClick={clickTopbar} to="/Cases" className='no-decoration MenuButtonMobile'>
      <div className='menu-button MenuButtonMobile'>
        <div className='menu-button-text'>Cases</div>
          <Mask mask={'url('+file+')'}/>
        </div>
      </Link>
      
    </div>
  </div>

  <div className='Menu desktop'>
    <Link to="/" className='no-decoration'>
      <img className='logo' src={napalm}/>
    </Link>
    <div className='menu-button-container'>

      <Link to="/UnderConstruction" className='no-decoration'>
        <div className='menu-button'>
          <div className='menu-button-text'>Aprendizagem</div>
          <Mask mask={'url('+phone+')'}/>
        </div>
      </Link>
      
      <Link to="/VR" className='no-decoration'>
        <div className='menu-button'>
          <div className='menu-button-text'>Realidade Virtual</div>
          <Mask mask={'url('+cube+')'}/>
        </div>
      </Link>

      <Link to="/Animation" className='no-decoration'>
        <div className='menu-button'>
          <div className='menu-button-text'>Animações 3D</div>
          <Mask mask={'url('+playCircle+')'}/>
        </div>
      </Link>

      <Link to="/UnderConstruction" className='no-decoration'>
        <div className='menu-button'>
          <div className='menu-button-text'>Jogos e Aplicativos</div>
          <Mask mask={'url('+gamepad+')'}/>
        </div>
      </Link>

      <Link to="/Cases" className='no-decoration'><div className='menu-button'>
        <div className='menu-button-text'>Cases</div>
          <Mask mask={'url('+file+')'}/>
        </div>
      </Link>
      
    </div>
  </div>
  </>
);

Menu.propTypes = {};

Menu.defaultProps = {};

export default Menu;
