import React from 'react';
import PropTypes from 'prop-types';
import './Form.css'
import '../../index.css'
import napalm from './images/NapalmLogo_big.png'



function Content(props){
  var title=props.title;
  var img=props.img;
  var content=props.content
  
  return(
    <div className='flex col align content' >
      
      <div className='flex row'>
          <div className='content-text flex col align'>
            <div className='gradient-text'><h2>{title}</h2></div>
            <div className='content-text flex col mobile'>
              {img}
            </div>
            {content}
            <div className='bot button gradient-text'>Saiba Mais</div>

          </div>
          <div className='content-text flex col desktop'>
          {img}
          </div>
         
      </div>
    </div>
  );

}

var mandatoryInputs=[];
mandatoryInputs.push('form-Name');
mandatoryInputs.push('form-Phone');
mandatoryInputs.push('form-Email');
function submitForm(){
  
  var validForm=true;

  for(var i=0;i<mandatoryInputs.length;i++){
    if(document.getElementById(mandatoryInputs[i]).value==''){
      validForm=false;
      if(document.getElementById(mandatoryInputs[i]).classList.contains('red-outline')==false){
        document.getElementById(mandatoryInputs[i]).classList.add('red-outline');
      }
      console.log("Invalid "+mandatoryInputs[i]);
    }
  }
  
  if(validForm){
    document.getElementById('form-submit').submit();
  }

}

function onClickCheckbox(event,e){
 // event.preventDefault();
  
  if(e=="Yes"){
    var yes= document.getElementById('CanSend_yes');
    if(yes.checked){
      document.getElementById('CanSend').checked=true;
      document.getElementById('CanSend_no').checked=false;
    }
    else{
      document.getElementById('CanSend').checked=false;
      document.getElementById('CanSend_no').checked=true;
    }
    
  }

  if(e=="No"){
    var no= document.getElementById('CanSend_no');
    if(no.checked){
      document.getElementById('CanSend').checked=false;
      document.getElementById('CanSend_yes').checked=false;
    }
    else{
      document.getElementById('CanSend').checked=true;
      document.getElementById('CanSend_yes').checked=true;
    }
    
  }

}

const Form = () => {
  

 
  

  return(
  <form id='form-submit' method='post' action='https://generalservices.napalm.studio/Home/SendForm'>
<script src="https://ajax.googleapis.com/ajax/libs/jquery/1.11.1/jquery.min.js"></script>
  <div  className='main-form'>
      <img className='form-logo' src={napalm}/>
      <div className='form-title'>
        Evento Out Of Home e Tecnologia Virtual
      </div>
      <div className='form-text'>
        <b>Agenda</b> <br></br>
        8h - 10h30 - 4D Learning -  Treinamentos corporativos para potencializar seus talentos 10h30 - 11h - Cofee e Networking
        11h - 13h -  Experiência Urbana em 3D - Experiência na Midia Out of Home
      </div>
      <div className='form-input-holder'>
        Nome e Sobrenome *
        <input id='form-Name' name='Name' placeholder='Sua Resposta'></input>
      </div>
      <div className='form-input-holder'>
        E-Mail
        <input id='form-Email' name='EMail' placeholder='Sua Resposta'></input>
      </div>
      <div className='form-input-holder'>
        Telefone
        <input id='form-Phone' name='Phone' placeholder='Sua Resposta'></input>
      </div>
      <div className='form-input-holder'>
        Cargo
        <input id='form-Occupation' name='Occupation' placeholder='Sua Resposta'></input>
      </div>
      <div className='form-input-holder'>
       Empresa
        <input id='form-Company' name='Company' placeholder='Sua Resposta'></input>
      </div>
      <div className='form-checkbox-holder'>
        Eu autorizo contato comigo
        
        <div className='flex row '>
          <input id='CanSend' type='checkbox' name='CanSend' value={true} checked style={{display:'none'}}></input>
          <label>
          <input id='CanSend_yes' type="checkbox"   onClick={(e)=>onClickCheckbox(e,'Yes')} defaultChecked="true" />Sim</label>
          <label>
          <input id='CanSend_no' type="checkbox"   onClick={(e)=>onClickCheckbox(e,'No')}/>Não</label>
        
        </div>
      </div>
      <button className='form-button' type='button' onClick={submitForm}>
        Enviar
      </button>
      
  </div>
  </form>
  );

};

Form.propTypes = {};

Form.defaultProps = {};

export default Form;
