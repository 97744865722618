import React from 'react';
import PropTypes from 'prop-types';
import './animation.css'
import '../../index.css'
import PageButton from '../../components/PageButton/PageButton';

import napalmlogo from './images/NapalmLogo_big.png';
import headset2 from './images/headset2.png';

import lebes from './images/lebes.png';
import stihl from './images/Stihl.png';
import g8 from './images/g8.png';
import Coopercarga from './images/Coopercarga.png';
import expresso from './images/expresso.png';
import top from './images/Tap.png';
import aurora from './images/aurora.png';
import SBD from './images/SBD.png';
import jogos from './images/Jogos.png';
import imagina from './images/imagina.png';

import GltfScene from '../../components/gltf_scene/gltf_scene';
import ReactPlayer from 'react-player'
//PAGEBUTTONS
import heroi from '../../content/images/heroi.png';
import smartphone from '../../content/images/celular.png';
import b_headset from '../../content/images/head-set.png';
import tela from '../../content/images/Tela_.png';
import pasta from '../../content/images/Pasta.png';

//GIFS
import celularGif from '../../content/gifs/celular.gif';
import heroiGif from '../../content/gifs/heroi.gif';
import b_headsetGif from '../../content/gifs/head-set.gif';
import telaGif from '../../content/gifs/Tela_.gif';
import pastaGif from '../../content/gifs/Pasta.gif';


function Content(props){
  var title=props.title;
  var img=props.img;
  var content=props.content
  
  return(
    <div className='flex col align content' >
      
      <div className='flex row'>
          <div className='content-text flex col align'>
          <div className='gradient-text'><h2>{title}</h2></div>
            {content}
            <div className='bot button gradient-text'>Saiba Mais</div>

          </div>
          <div className='content-text flex col'>
          {img}
          </div>
         
      </div>
    </div>
  );

}


const Animation = () => (
  <div className='main-Cases'>
    <div className="video-container">
        <div className="video-overlay">
            <div className="text-overlay">ESTA</div>
            <div className="text-overlay">PÁGINA</div>
            <div className="text-overlay">ESTÁ</div>
            <div className="text-overlay">EM</div>
            <div className="text-overlay">CONSTRUÇÃO</div>
            
        </div>
        <video  id="bg-video" className='desktop'  src="./videos/fundo_website.mp4" autoPlay muted loop type="video/mp4"/>
        <video  id="bg-video" className='mobile'  src="./videos/fundo_website_vertical.mp4" autoPlay muted loop type="video/mp4"/>
    </div>

    <div className='flex row align button-container wrap top-50p bot-50p desktop'>
      <PageButton Text='Aprendizagem Corporativa' img={smartphone} gif={celularGif} Link='/UnderConstruction'/>
      <PageButton Text='Realidade Virtual ' img={b_headset} gif={b_headsetGif} Link='/UnderConstruction'/>
      <PageButton Text='Animações Imagens 3D' img={tela} gif={telaGif} Link='/Animation'/>
      <PageButton Text='Jogos e Aplicativos' img={heroi} gif={heroiGif} Link='/UnderConstruction'/>
      <PageButton Text='Cases' img={pasta} gif={pastaGif} Link='/Cases'/>
    </div>
    
    
  </div>
);

Animation.propTypes = {};

Animation.defaultProps = {};

export default Animation;
