import React from 'react';

import '../../index.css'
import './VR.css'





function Content(props){
  var title=props.title;
  var img=props.img;
  var content=props.content
  
  return(
    <div className='flex col align content' >
      
      <div className='flex row'>
          <div className='content-text flex col align'>
          <div className='gradient-text'><h2>{title}</h2></div>
            {content}
            <div className='bot button gradient-text'>Saiba Mais</div>

          </div>
          <div className='content-text flex col'>
          {img}
          </div>
         
      </div>
    </div>
  );

}


const VR = () => (<>
  <div className='main-Cases desktop'>
    <div className="video-container"
    style={{width:'100%', height:'100%'}}>
        <div className="video-overlay">
            <div className="text-overlay">Realidade</div>
            <div className="text-overlay">Virtual</div>
            <div className="text-overlay">e</div>
            <div className="text-overlay">Aumentada</div>
        </div>
        <video  id="bg-video" className='desktop'  src="./videos/Headset.mp4" autoPlay muted loop type="video/mp4"/>
        <video  id="bg-video" className='mobile'  src="./videos/fundo_website_vertical.mp4" autoPlay muted loop type="video/mp4"/>
    </div>

    
    
    
  </div>
  <div className='main-Cases desktop' style={{ 
    background: `red`,
    
  }}></div>
  </>
);

VR.propTypes = {};

VR.defaultProps = {};

export default VR;
